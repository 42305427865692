<template>
    <div class="details"  v-if="goodsdetails">
        <!-- 用户信息 -->
        <header-user-info types="goodsDetail" :type="types"></header-user-info>

        <!-- 详情介绍 -->
        <div class="details-wrap">
            <!-- 轮播图 倒计时 -->
            <detail-header :goodsDetails="goodsdetails" :specInfo="nowSpec" @changeBuyStatus="changeBuyStatus($event)">
            </detail-header>

            <!-- 标题 -->
            <div class="font">{{ goodsdetails.goods_name }}</div>
            <div class="flex-between bg-fff">
                <div class="tag-box">
                    <p>{{ goodsdetails.is_reserve == 1 ? "需预约" : "无需预约" }}</p>
                    <p>{{ goodsdetails.is_refund == 1 ? "可退款" : "不可退款" }}</p>
                </div>
                <div class="hot">{{ countVisit }}人喜欢</div>
            </div>
        </div>

        <div class="wrap">
            <div class="details-Specific">
                <div class="window-wrap-center">
                    <p>选择规格:</p>
                    <ul class="window-wrap-center-list">
                        <li :class="index == itemIndex ?'pink':''" @click="itemIndex = index"
                            v-for="(item,index) in specArr" :key="index">{{ item.item_value }}</li>
                    </ul>
                </div>
                <div class="spec-num">
                    <p>数量:</p>
                    <van-stepper v-model="buyNum" :min="stepperMin" :max="stepperMax" :integer="true"
                        input-width="2.2rem" button-size="0.58rem" @overlimit="overlimit" />
                </div>
            </div>
            <div class="store-detail">
                <!-- 店铺信息 -->
                <shops-info :shopArr="shopInfo" :goodsId="goodsdetails.goods_id"></shops-info>
                <div class="tabbar">
                    <van-tabs scrollspy sticky offset-top="0.88rem">
                        <van-tab title="图文详情">
                            <div class="indTabContent">
                                <div v-html="goodscontent.long_contents"></div>
                            </div>
                        </van-tab>
                        <van-tab title="购买须知">
                            <div class="indTabContent">
                                <div v-html="goodscontent.goods_notes"></div>
                            </div>
                        </van-tab>
                    </van-tabs>
                </div>
            </div>
        </div>

        <!-- 右侧推广按钮 -->
        <div class="share-icon" v-if="userInfo.user_types == 2" @click="toShareGoods"
            v-clipboard:copy="goodsdetails.goods_name + ' 下单链接: ' + shareLink">
            <p class="price">￥{{ commission }}</p>
        </div>

        <!-- 底部导航 -->
        <ul class="footer">
            <router-link to="/home" tag="li">
                <p>
                    <img src="@/assets/cp01.png" alt />
                </p>
                <p>首页</p>
            </router-link>
            <li v-show="userInfo.user_types == 2" @click="toShareGoods"
                v-clipboard:copy="goodsdetails.goods_name + ' 下单链接: ' + shareLink">
                <p>
                    <img src="@/assets/cp02.png" alt />
                </p>
                <p>海报</p>
            </li>
            <li>
                <div class="footer-c-top" v-if="nowSpec">
                    <p>￥{{ nowSpec.specs_price }}</p>
                    <s>￥{{ nowSpec.market_price }}</s>
                </div>
            </li>
            <li @click="goBuy">
                <p :class="{ disable: buyBtn.status != '2', start: buyBtn.status == '2'}">{{ buyBtn.btnTxt }}</p>
            </li>
        </ul>
        <share-poster ref="sharePoster"
            :goodsDetails="{goods_name:goodsdetails.goods_name, share_imgurl:goodsdetails.share_imgurl}"
            :userInfo="userInfo" :shareLink="shareLink" @close="closePoster" v-if="showPoster"></share-poster>
    </div>
    <div v-else>
        <van-empty description="商品详情">
            <router-link to="/home" v-if="goods_tips" >
                <van-button round type="danger" class="bottom-button">返回首页</van-button>
            </router-link>    
        </van-empty>
    </div>
</template>

<script>
    import {
        goodsDetails,
        getGoodsContent,
        getGoodsLists,
        checkCards,
    } from "@/http/api";
    import {
        changeVisit
    } from "@/common/data";
    import {
        onUserShare,
        getLocationInfo
    } from "@/common/wxJssdk";
    import HeaderUserInfo from "../home/children/Header";
    import DetailHeader from "./detailsChildren/DetailHeader";
    import ShopsInfo from "./detailsChildren/ShopsInfo";
    import SharePoster from "./detailsChildren/SharePoster";

    export default {
        components: {
            HeaderUserInfo,
            DetailHeader,
            ShopsInfo,
            SharePoster
        },
        data() {
            return {
                goods_tips: false,
                version: "",
                goodscontent: {},
                userToken: "",
                isShow: 1, //  加载标记
                share_id: 0, //  分享ID
                referrer_id: 0, //  推广员ID
                windowShow: 0, //  控制显示规格窗口标记
                buyBtn: {
                    status: 0,
                    btnTxt: "立即抢购"
                },
                showNum: 1, //  切换商品内容标记
                nums: 1, //  默认购买数量
                itemIndex: 0, //  默认规格下标
                goods_id: "",
                types: 1,

                userInfo: {},
                goodsdetails: {},
                specInfo: {},
                shopInfo: [],
                location: {},
                buy: 0,

                buyNum: 0,
                specArr: [],

                goodsitem: {},
                goodsList: [],
                distance1: 0,
                referrer: {},

                link: "",
                shareLink: "",
                showPoster: false, //  海报标记
                isClickShare: false //  生成海报标记
            };
        },
        mounted() {
            let route = this.$route.query;
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.userInfo = this.$LStorage.getItem("userInfo");

            if (route.types) {
                this.types = route.types
            }
            if (route.goods_id) {
                this.goods_id = route.goods_id;
                this.version = "2.0";
            }
            if (route.id) {
                this.goods_id = route.id;
                this.version = "1.0";
            }

            if (route.goods_id) {
                this.goods_id = route.goods_id;
                this.version = "2.0";
            }
            if (route.id) {
                this.goods_id = route.id;
                this.version = "1.0";
            }
 
            //  执行判断登录
            if (this.goods_id) {
                //  处理分享
                if (this.types == "2" && route.referrer_id) {
                    this.referrer_id = route.referrer_id;
                } else {
                    this.referrer_id = this.$LStorage.getItem("referrerInfo") ? this.$LStorage.getItem("referrerInfo") :
                        0;
                }
                this.goodsDetails();

            } else {
                this.$dialog.alert({
                    message: '商品页面参数异常, 点击跳转到首页看看',
                }).then(() => {
                    this.$router.replace({
                        path: "/home"
                    });
                });
            }
        },

        methods: {
            //  获取商品详情数据
            async goodsDetails() {
                const res = await goodsDetails({
                    data: {
                        goods_id: this.goods_id,
                        user_token: this.userToken,
                        share_id: this.share_id,
                        referrer_id: this.referrer_id,
                        version: this.version
                    }
                });
                if (res.code != 200) {
                    this.isShow = 0;
                    this.$toast.fail(res.msgs);
                    this.goods_tips = true;
                } else {
                    this.isShow = 2;
                    this.goodsdetails = res.data;
                    // console.log(this.goodsdetails);
                    this.specArr = res.data.specs_list;
					
					this.getGoodsContent();
                    
                    //  执行创建分享
                    this.onUserShareGoods({
                        title: res.data.goods_name,
                        imgUrl: res.data.cover_imgurl
                    });
                    //  执行获取店铺列表数据
					let that = this;
					getLocationInfo("gcj02", function(locationData) {
						console.log(locationData)
						let curSiteInfo = that.$LStorage.getItem('curSiteInfo');
						if (locationData) {
							that.location = {
								location_lng: locationData.longitude,
								location_lat: locationData.latitude,
							}
						} else {
							that.location = {
								location_lng: curSiteInfo.location_lng,
								location_lat: curSiteInfo.location_lat,
							}
						}
						that.getGoodsLists(that.goodsdetails.goods_id);
					}); 
                }
            },
            //获取图文详情
            async getGoodsContent() {
                const res = await getGoodsContent({
                    data: {
                        goods_id: this.goods_id
                    }
                });
                this.goodscontent = res.data;
            },

            //  获取店铺列表
            async getGoodsLists(goods_id) {
                const res = await getGoodsLists({
                    data: {
                        location_lng: this.location.location_lng,
                        location_lat: this.location.location_lat,
                        goods_id: goods_id
                    }
                });
				console.log(res)
                this.shopInfo = res.data.splice(0, 1);
            },


            //  商品分享
            onUserShareGoods(goods) {
                let site_code = this.$LStorage.getItem("curSiteInfo").site_code;
                if (this.userInfo.user_types == 1) { 
                    goods.link = "/details?goods_id=" + this.goods_id + "&types=1&site_code=" + site_code;
                    goods.desc = "饭十荟带您花的少吃的好";
                } else {
                    goods.link = "/details?goods_id=" + this.goods_id + "&types=2&referrer_id=" + this.userInfo
                        .user_code + "&site_code=" + site_code;
                    goods.desc = "饭十荟【" + this.userInfo.user_name + "】掌柜带您花的少吃的好";
                }
                this.shareLink = window.location.origin + goods.link;
                onUserShare(goods);
            },

            // 去购买
            goBuy() {
                let nowSpec = this.nowSpec;
                if (nowSpec.specs_stock >= this.buyNum) {
                    this.checkCards();
                } else {
                    this.$toast("库存不足");
                }
            },


            //  执行交易预结算
            async checkCards() {
                const res = await checkCards({
                    data: {
                        user_token: this.userToken,
                        goods_id: this.goods_id,
                        goods_nums: this.buyNum,
                        goods_specs_id: this.nowSpec.goods_specs_id
                    }
                });
                if (res.code == 200) {
                    //跳转商品结算页面
                    this.$router.push({
                        path: "/settlement",
                        query: {
                            id: res.data.buffer_id,
                            share_id: this.share_id,
                            referrer_id: this.referrer_id
                        }
                    });
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            //  按钮文字变化
            changeBuyStatus(datas) {
                this.buyBtn = datas;
            },

            // 限制购买数量
            overlimit(e) {
                if (e == "minus") {
                    if (this.nowSpec.specs_stock == 0) {
                        this.$toast("不能再少啦");
                    } else {
                        this.$toast(this.goodsdetails.buy_least + "件起购");
                    }
                } else {
                    if (this.goodsdetails.buy_quota > 0) {
                        this.$toast("限购" + this.stepperMax + "份");
                    } else {
                        this.$toast("库存不足");
                    }
                }
            },

            //  点击分享
            toShareGoods() {
                if (this.isClickShare) {
                    this.showPoster = true;
                } else {
                    this.isClickShare = true;
                    this.$toast.loading("正在创建");
                    this.$toast.clear();
                    this.showPoster = true;
                }
            },

            //  关闭分享海报
            closePoster() {
                this.showPoster = false;
                // this.$refs.sharePoster.isShow = true
            },

        },

        computed: {
            //  计算饭佣
            commission() {
                let nowSpec = this.nowSpec;
                if (nowSpec) {
                    let referrer_lv = this.userInfo.referrer_lv,
                        commission1 = Number(nowSpec.commission1),
                        commission2 = Number(nowSpec.commission2);
                    if (referrer_lv == 1) {
                        return commission2;
                    }
                    if (referrer_lv == 2) {
                        return commission1;
                    }
                }
            },
            //  计算访问
            countVisit() {
                return changeVisit(this.goodsdetails.total_visit);
            },
            // 当前规格
            nowSpec() {
                if (this.goodsdetails.specs_list) {
                    return this.specArr[this.itemIndex];
                }
            },
            // 计算最大购买数量
            stepperMax() {
                let buyQ = this.goodsdetails.buy_quota;
                if (buyQ == 0) {
                    return this.nowSpec.specs_stock;
                } else {
                    return buyQ;
                }
            },
            // 计算最小购买数量
            stepperMin() {
                let buyL = this.goodsdetails.buy_least;
                if (this.nowSpec && this.nowSpec.specs_stock == 0) {
                    return 0;
                } else {
                    return buyL;
                }
            }
        },

        created() {}
    };
</script>

<style lang="scss">
    .details {
        width: 100%;
        padding-bottom: 1.5rem;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        .home-nav {
            background: none;
            padding: 0;
            height: auto;
            width: auto;
            z-index: 888;
        }

        .user-info {
            // background-color: rgba(254, 230, 218, 0.9);
            background-color: rgba(254, 230, 218, 1);
            height: 0.88rem;
            width: 100%;
            box-sizing: border-box;
            padding: 0 0.3rem;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 999;
            display: flex;
            align-items: center;

            .logo {
                width: 1.74rem;
            }

            .user-box {
                height: 100%;
                display: flex;
                align-items: center;

                .headimg {
                    width: 0.58rem;
                    height: 0.58rem;
                    border-radius: 50%;
                    margin-right: 0.12rem;
                }

                .nickname {
                    font-size: 0.3rem;
                    color: #333;
                    width: 2rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .details-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .details-wrap {
            width: 100%;
            height: 100%;
            background: #f6f6f6;

            // margin-bottom: 0.2rem;
            .font {
                padding: 0.31rem 0.23rem 0.3rem 0.23rem;
                box-sizing: border-box;
                text-indent: 0.18rem;
                font-size: 0.32rem;
                background-color: #fff;
                // background: #fff;
            }

            .tag-box {
                display: flex;
                align-items: center;

                p {
                    background-color: #ffeaef;
                    color: #e40011;
                    border-radius: 0.05rem;
                    padding: 0 0.1rem;
                    height: 0.48rem;
                    line-height: 0.48rem;
                    text-align: center;
                    margin-right: 0.13rem;
                }
            }

            .hot {
                background: url(../../assets/hot.png) no-repeat left center;
                background-size: 0.28rem;
                padding-left: 0.34rem;
                color: #323233;
            }
        }

        .flex-between {
            margin-bottom: 0.2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.23rem;
            padding-bottom: 0.3rem;
            font-size: 0.26rem;
        }

        .bg-fff {
            background-color: #fff;
        }

        //  选择规格
        .wrap {
            width: 100%;
            height: 100%;
            background: #f6f6f6;

            .store-detail {
                // background: #fff;

                .tabbar {
                    width: 100%;
                    margin-top: 0.2rem;
                    background: #fff;

                    .indeTab {
                        width: 100%;

                        ul {
                            display: flex;
                            height: 1rem;
                            line-height: 1rem;
                            align-items: center;

                            li {
                                width: 50%;
                                height: 100%;
                                border-bottom: 1px solid #eeeeee;
                                text-align: center;
                                font-size: 0.28rem;
                                padding-bottom: 0.14rem;
                                box-sizing: border-box;
                            }

                            .on {
                                border-bottom: 1px solid red;
                            }
                        }
                    }

                    .indTabContent {
                        width: calc(100% - 0.48rem);
                        padding: 0.24rem;
                        font-size: 0.3rem;

                        div {
                            img {
                                width: 100% !important;
                                display: block;
                            }
                        }

                        .munu {
                            padding: 0.41rem 0.36rem 0 0.25rem;
                            box-sizing: border-box;

                            .showNum {
                                text-indent: 0.13rem;
                                font-size: 0.26rem;
                            }

                            ul {
                                margin-top: 0.71rem;

                                p {
                                    text-indent: 0.13rem;
                                    font-size: 0.26rem;
                                }

                                li {
                                    font-size: 0.26rem;
                                    height: 0.7rem;
                                    line-height: 0.7rem;
                                }
                            }

                            .Tips {
                                font-size: 0.25rem;
                                margin-top: 0.75rem;
                            }
                        }

                        .view1 {
                            font-size: 0.3rem;
                            text-align: center;
                            margin-top: 0.2rem;
                        }
                    }
                }
            }
        }

        // 右侧悬浮分享
        .share-icon {
            width: 2.15rem;
            height: 1.74rem;
            position: fixed;
            right: 0.1rem;
            bottom: 16%;
            background: url("../../assets/cp06.png") no-repeat center;
            background-size: cover;

            .price {
                font-size: 0.4rem;
                color: #eb1200;
                text-align: center;
                padding-top: 0.06rem;
            }
        }

        .footer {
            width: 100%;
            height: 1.16rem;
            display: flex;
            background: #fff;
            box-shadow: 0.03rem 0rem 0.16rem 0rem rgba(0, 0, 0, 0.1);
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 2000;
            // padding: 0.23rem 0.25rem 0.22rem 0.38rem;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-evenly;

            li {
                padding: 0;
                box-sizing: border-box;
                text-align: center;
                // margin-right: 0.48rem;
            }

            li:nth-child(1) {
                width: 0.8rem;

                p {
                    margin-bottom: 0.18rem;

                    img {
                        width: 0.3rem;
                        height: 0.3rem;
                    }

                    font-size: 0.23rem;
                }
            }

            li:nth-child(2) {
                width: 0.8rem;
                border-right: 1px solid #eee;
                padding-right: 0.27rem;
                box-sizing: border-box;
                margin-right: 0;

                p {
                    margin-bottom: 0.18rem;

                    img {
                        width: 0.3rem;
                        height: 0.3rem;
                    }

                    font-size: 0.23rem;
                }
            }

            li:nth-child(3) {
                padding-left: 0.1rem;
                box-sizing: border-box;

                .footer-c-top {
                    // height: 50%;
                    display: flex;

                    p:nth-child(1) {
                        font-size: 0.3rem;
                        color: #e50012;
                        margin-right: 0.12rem;
                    }

                    s {
                        font-size: 0.25rem;
                        color: #c8c8c8;
                        margin-top: 0.05rem;
                    }
                }

                .footer-c-bottom {
                    padding: 0.05rem 0.08rem;
                    width: fit-content;
                    // height: 0.37rem;
                    background: #ffdfe2;
                    color: #e50012;
                    text-align: center;
                    // line-height: 0.37rem;
                    font-size: 0.2rem;
                    border-radius: 0.04rem;
                    // padding: 0.1rem 0.09rem 0.08rem 0.1rem;
                    // box-sizing: border-box;
                }
            }

            li:nth-child(4) {
                width: 2.31rem;
                height: 0.84rem;
                text-align: center;
                line-height: 0.84rem;
                font-size: 0.3rem;
                border-radius: 0.42rem;
                margin-left: 0.15rem;
                overflow: hidden;

                p {
                    color: #fff;
                    background: #e50012;
                }

                .disable {
                    background: #b3b3b3;
                }
            }

            .two {
                background: #606060;
            }
        }
    }

    .details-Specific {
        width: 100%;
        background: #fff;
        padding: 0 0.24rem;
        box-sizing: border-box;
        border-top: #f6f6f6 solid 0.2rem;
        margin-bottom: 0.2rem;

        .window-wrap-center {
            width: 100%;

            p {
                margin-top: 0.37rem;
                font-size: 0.26rem;
                color: #767676;
            }

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    font-size: 0.24rem;
                    // width: 2rem;
                    padding: 0 0.12rem;
                    height: 0.7rem;
                    line-height: 0.7rem;
                    text-align: center;
                    background: #f1f1f1;
                    margin-right: 0.17rem;
                    margin-top: 0.22rem;
                    border-radius: 0.08rem;
                }

                .pink {
                    background: #fce0e0;
                }
            }
        }

        .spec-num {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 1.2rem;

            p {
                font-size: 0.26rem;
                color: #767676;
            }
        }

        .details-Specific-left {
            display: flex;

            p {
                font-size: 0.26rem;
            }

            p:nth-child(1) {
                color: #a2a2a2;
                margin-right: 0.42rem;
            }

            p:nth-child(2) {
                color: #3f3f3f;
            }
        }

        svg {
            display: block;
        }
    }

    .window {
        width: 100%;

        .window-bg {
            width: 100%;
            height: 100vh;
            background: rgba(0, 0, 0, 0.3);
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 1000;
        }

        .window-wrap {
            width: 100%;
            // height: 7.66rem;
            background: #fff;
            opacity: 1;
            z-index: 2000;
            padding: 0.24rem;
            box-sizing: border-box;
            // margin-top: 6rem;
            position: fixed;
            bottom: 0;
            left: 0;

            .window-wrap-top {
                display: flex;

                div:nth-child(1) {
                    img {
                        width: 2.45rem;
                        height: 1.8rem;
                    }
                }

                div:nth-child(2) {
                    width: 100%;
                    margin-left: 0.19rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    p:nth-child(1) {
                        width: 4.21rem;
                        font-size: 0.36rem;
                        margin-top: 0.1rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #767676;
                    }

                    p:nth-child(2) {
                        font-size: 0.23rem;
                        color: #999;
                    }

                    p:nth-child(3) {
                        font-size: 0.4rem;
                        color: #e5232f;
                    }
                }
            }

            .window-wrap-center {
                width: 100%;

                p {
                    margin-top: 0.37rem;

                    font-size: 0.26rem;
                    color: #767676;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        font-size: 0.24rem;
                        // width: 2rem;
                        padding: 0 0.12rem;
                        height: 0.7rem;
                        line-height: 0.7rem;
                        text-align: center;
                        background: #f1f1f1;
                        margin-right: 0.17rem;
                        margin-top: 0.22rem;
                        border-radius: 0.08rem;
                    }

                    .pink {
                        background: #fce0e0;
                    }
                }
            }

            .window-wrap-number {
                width: 100%;

                p {
                    font-size: 0.26rem;
                    margin-top: 0.46rem;
                }

                ul {
                    margin-top: 0.18rem;
                    display: flex;

                    li {
                        text-align: center;
                        height: 0.72rem;
                        line-height: 0.72rem;
                    }

                    li:nth-child(1) {
                        width: 0.92rem;
                        color: #cccccc;
                        font-size: 0.3rem;
                        border: 1px solid #ddd;
                        border-right: 0;
                    }

                    li:nth-child(2) {
                        width: 1.3rem;
                        font-size: 0.3rem;
                        border: 1px solid #ddd;
                        border-right: 0;
                    }

                    li:nth-child(3) {
                        color: #cccccc;
                        width: 0.94rem;
                        font-size: 0.3rem;
                        border: 1px solid #ddd;
                    }
                }
            }

            .window-wrap-button {
                width: 6.8rem;
                height: 0.84rem;
                font-size: 0.3rem;
                background: #e50012;
                color: #fff;
                line-height: 0.84rem;
                text-align: center;
                margin-top: 0.4rem;
                border-radius: 0.44rem;
            }
        }
    }
</style>
