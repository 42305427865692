<template>
	<div class="details-wrap-top">
		<mt-swipe :auto="3000" class="swiper">
			<mt-swipe-item v-for="(item, index) in goodsDetails.show_imgurl" :key="index">
				<img :src="item" />
			</mt-swipe-item>
		</mt-swipe>

		<div class="details-position">
			<div class="details-position-1">
				<ul class="text-price" v-if="specInfo">
					<li>
						<span>￥{{ specInfo.specs_price }}</span>
						<s>￥{{ specInfo.market_price }}</s>
					</li>
					<li>
						<div class="font1">
							已售{{ goodsDetails.total_sale }}&nbsp;&nbsp;&nbsp;&nbsp;剩余{{
                            specInfo.specs_stock
                            }}份
						</div>
					</li>
				</ul>
			</div>
			<!-- <p></p> -->
			<div class="close">
				<span>{{ timerArr.timerText }}</span>
				<van-count-down :time="timerArr.timer" class="timer flex">
					<template #default="timeData">
						<p class="block">{{ timeData.days | timeNum }}</p>
						<span class="colon">天</span>
						<p class="block">{{ timeData.hours | timeNum }}</p>
						<span class="colon">:</span>
						<p class="block">{{ timeData.minutes | timeNum }}</p>
						<span class="colon">:</span>
						<p class="block">{{ timeData.seconds | timeNum }}</p>
					</template>
				</van-count-down>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			goodsDetails: {
				type: Object,
				default () {
					return {};
				}
			},
			specInfo: {
				type: Object,
				default () {
					return {};
				}
			}
		},
		data() {
			return {
				intv: "",
				timerArr: {
					timer: 0,
					timerText: ""
				}
			};
		},
		created() {
		},
		watch: {
			goodsDetails(data) {
				//  初始化执行一次
				this.timerCount();
				// this.intVl();
			} 
		},
		methods: {
			timerCount() {
				let timerText = "",
					btnTxt = "",
					status = 2,
					timer = 0;
				let nowTime = new Date().getTime();
				if (this.goodsDetails.sale_status == 0) {
					btnTxt = "已结束";
					timerText = "活动已结束";
					status = "0";
				} else if (this.goodsDetails.sale_status == 1) {
					btnTxt = "暂未开始";
					timerText = "距离活动开始还剩";
					status = "1";
					timer = this.goodsDetails.start_date * 1000 - nowTime;
				} else if (this.goodsDetails.sale_status == 2) {
					timerText = "距离活动结束还剩";
					timer = this.goodsDetails.end_date * 1000 - nowTime;
					if (this.goodsDetails.goods_stock > 0) {
						btnTxt = "立即抢购";
						status = "2";
					} else {
						btnTxt = "抢光了";
						status = "0";
					}
				}
				this.$emit("changeBuyStatus", {
					btnTxt,
					status
				});
				this.timerArr = {
					timer,
					timerText
				};
				return {
					timer,
					timerText
				};
			}
		},

		filters: {
			timeNum(data) {
				if (data < 10) {
					return "0" + data;
				} else {
					return data;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.timer {
		justify-content: center;
	}

	.colon {
		display: inline-block;
		margin: 0 0.08rem;
		color: #fff;
	}

	.block {
		display: inline-block;
		width: 0.35rem;
		height: 0.35rem;
		line-height: 0.35rem;
		border-radius: 0.05rem;
		color: #fff;
		font-size: 0.24rem;
		text-align: center;
		background-color: #ee0a24;
	}

	.details-wrap-top {
		position: relative;

		img {
			width: 100%;
			height: 5.93rem;
			display: block;
		}

		.swiper {
			width: 100%;
			height: 5.93rem;
			display: block;
		}

		.details-position {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			display: flex;

			.details-position-1 {
				width: 3.67rem;
				height: 1.11rem;
				background: #f7cf46;
				padding: 0.17rem 0 0.15rem 0.23rem;
				box-sizing: border-box;
				position: relative;
				z-index: 99;

				.text-price {
					width: 100%;

					li {
						display: flex;

						span {
							display: block;
							font-size: 0.38rem;
							color: #e40011;
						}

						s {
							font-size: 0.18rem;
							color: #9e7d0c;
							padding-top: 0.15rem;
							padding-left: 0.24rem;
							box-sizing: border-box;
						}

						.font1 {
							display: flex;
							font-size: 0.2rem;
							margin: 0;
						}
					}

					li:nth-child(2) {
						font-size: 0.2rem;
						color: #000000;
						padding-left: 0.08rem;
						box-sizing: border-box;
						margin-top: 0.06rem;

						span {
							color: #000000;
							font-size: 0.2rem;
						}
					}
				}
			}

			::after {
				content: "";
				width: 0;
				height: 0;
				border-bottom: 1.11rem solid #f7cf46;
				border-right: 0.8rem solid rgba(0, 0, 0, 0);
				z-index: 100;
				position: absolute;
				right: -0.8rem;
				bottom: 0;
				z-index: 99;
			}

			// p:nth-child(2) {

			// }

			.close {
				width: 3.67rem;
				height: 0.9rem;
				background: #333333;
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: 1;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: center;
				padding-right: 0.24rem;

				span:nth-child(1) {
					display: block;
					color: #fff;
					font-size: 0.22rem;
					text-align: center;
					margin-bottom: 0.1rem;
				}

				.tiem {
					display: flex;

					li {
						width: 0.35rem;
						height: 0.35rem;
						font-size: 0.25rem;

						span {
							display: block;
							width: 0.35rem;
							height: 0.35rem;
							background: #e40011;
						}
					}
				}

				.font2 {
					display: flex;
					font-size: 0.2rem;
					margin-left: 1rem;
					margin-top: -0.1rem;
					color: #fff;

					p {
						font-size: 0.2rem;
						width: 0.35rem;
						height: 0.35rem;
						background: #e40011;
						color: #fff;
						text-align: center;
						line-height: 0.35rem;
						border-radius: 0.04rem;
						margin-right: 0.1rem;
						margin-left: 0.1rem;
					}
				}
			}
		}
	}
</style>
